<template>
  <div class="shop_renovation">
    <div class="title">店铺装修</div>
    <div class="renovation_item">
      <el-checkbox class="item_title">店面招牌头部</el-checkbox>
      <cus-upload class="item_upload"  @change="add_tupian($event, 0 ,0)"></cus-upload>
    </div>
    <!-- 1 -->
    <div class="renovation_item">
      <el-checkbox class="item_title">轮播图</el-checkbox>
      <div class="item_inline">
        <div
          class="lunbo_item"
          v-for="(item, index) in lunbo_list"
          :key="index"
        >
          <cus-upload
            class="img"
            @change="add_tupian($event, index ,1)"
          ></cus-upload>
          <div class="add_goods" @click="add_lunbo(index, 1 ,item.goods_id )">
            <span v-if="item.goods_id != ''">id:{{ item.goods_id }}</span>
            <span v-else>+ 选择商品</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 2 -->
    <div class="renovation_item">
      <el-checkbox class="item_title">专题活动</el-checkbox>
      <div class="item_bolck">
        <div class="item_inline">
          <CusInput class="inp" @chenginput="change_input($event,2)" :value="special1.title" :placeholder="'请输入活动标题名字'"></CusInput>
        </div>
        <div class="item_inline">
          <div class="lunbo_item" style="flex: 1">
            <cus-upload class="img" @change="add_tupian($event, 0 ,2)"></cus-upload>
            <div class="add_goods" @click="add_lunbo(0, 2 ,special1.item[0].goods_id )">
              <span v-if="special1.item[0].goods_id != ''">id:{{ special1.item[0].goods_id }}</span>
              <span v-else>+ 选择商品</span>
            </div>
          </div>
          <div class="lunbo_item">
            <cus-upload class="img"  @change="add_tupian($event, 1 ,2)"></cus-upload>
            <div class="add_goods" @click="add_lunbo(1, 2 ,special1.item[1].goods_id )">
              <span v-if="special1.item[1].goods_id!= ''">id:{{ special1.item[1].goods_id }}</span>
              <span v-else>+ 选择商品</span>
            </div>
          </div>
        </div>
        <div class="item_inline">
          <div class="lunbo_item">
            <cus-upload class="img"  @change="add_tupian($event, 2 ,2)"></cus-upload>
            <div class="add_goods" @click="add_lunbo(2, 2 ,special1.item[2].goods_id )">
              <span v-if="special1.item[2].goods_id != ''">id:{{ special1.item[2].goods_id }}</span>
              <span v-else>+ 选择商品</span>
            </div>
          </div>
          <div class="lunbo_item">
            <cus-upload class="img"  @change="add_tupian($event, 3 ,2)"></cus-upload>
            <div class="add_goods" @click="add_lunbo(3, 2 ,special1.item[3].goods_id )">
              <span v-if="special1.item[3].goods_id != ''">id:{{ special1.item[3].goods_id }}</span>
              <span v-else>+ 选择商品</span>
            </div>
          </div>
          <div class="lunbo_item">
            <cus-upload class="img"  @change="add_tupian($event, 4 ,2)"></cus-upload>
            <div class="add_goods" @click="add_lunbo(4, 2 ,special1.item[4].goods_id)">
              <span v-if="special1.item[4].goods_id != ''">id:{{ special1.item[4].goods_id }}</span>
              <span v-else>+ 选择商品</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 3 -->
    <div class="renovation_item">
      <el-checkbox class="item_title">专题活动</el-checkbox>
      <div class="item_inline">
        <div class="lunbo_item" style="width: 400px">
          <cus-upload class="img"></cus-upload>
          <div class="add_goods" @click="add_lunbo">+ 选择商品</div>
        </div>
      </div>
    </div>
    <!-- 4 -->
    <div class="renovation_item">
      <el-checkbox class="item_title">专题活动</el-checkbox>
      <div class="item_bolck">
         <div class="item_inline">
          <CusInput class="inp" @chenginput="change_input($event,4)" :value="special2.title" :placeholder="'请输入活动标题名字'"></CusInput>
        </div>
        <div class="item_inline">
          <div class="lunbo_item" style="flex: 1">
            <cus-upload class="img"  @change="add_tupian($event, 0 ,4)"></cus-upload>
            <div class="add_goods" @click="add_lunbo(0, 4 ,special2.item[0].goods_id )">
              <span v-if="special2.item[0].goods_id != ''">id:{{ special2.item[0].goods_id }}</span>
              <span v-else>+ 选择商品</span>
            </div>
          </div>
        </div>
        <div class="item_inline">
          <div class="lunbo_item">
            <cus-upload class="img"  @change="add_tupian($event, 1 ,4)"></cus-upload>
            <div class="add_goods" @click="add_lunbo(1, 4 ,special2.item[1].goods_id )">
              <span v-if="special2.item[1].goods_id != ''">id:{{ special2.item[1].goods_id }}</span>
              <span v-else>+ 选择商品</span>
            </div>
          </div>
          <div class="lunbo_item">
            <cus-upload class="img"  @change="add_tupian($event, 2 ,4)"></cus-upload>
            <div class="add_goods" @click="add_lunbo(2, 4 ,special2.item[2].goods_id )">
              <span v-if="special2.item[2].goods_id != ''">id:{{ special2.item[2].goods_id }}</span>
              <span v-else>+ 选择商品</span>
            </div>
          </div>
          <div class="lunbo_item">
            <cus-upload class="img"  @change="add_tupian($event, 3 ,4)"></cus-upload>
            <div class="add_goods" @click="add_lunbo(3, 4 ,special2.item[3].goods_id)">
              <span v-if="special2.item[3].goods_id != ''">id:{{ special2.item[3].goods_id }}</span>
              <span v-else>+ 选择商品</span>
            </div>
          </div>
        </div>
        <div class="item_inline">
          <div class="lunbo_item">
            <cus-upload class="img"  @change="add_tupian($event, 4 ,4)"></cus-upload>
            <div class="add_goods" @click="add_lunbo(4, 4 ,special2.item[4].goods_id )">
              <span v-if="special2.item[4].goods_id != ''">id:{{ special2.item[4].goods_id }}</span>
              <span v-else>+ 选择商品</span>
            </div>
          </div>
          <div class="lunbo_item">
            <cus-upload class="img"  @change="add_tupian($event, 5 ,4)"></cus-upload>
            <div class="add_goods" @click="add_lunbo(5, 4 ,special2.item[5].goods_id )">
              <span v-if="special2.item[5].goods_id != ''">id:{{ special2.item[5].goods_id }}</span>
              <span v-else>+ 选择商品</span>
            </div>
          </div>
          <div class="lunbo_item">
            <cus-upload class="img"  @change="add_tupian($event, 6 ,4)"></cus-upload>
            <div class="add_goods" @click="add_lunbo(6, 4 ,special2.item[6].goods_id)">
              <span v-if="special2.item[6].goods_id != ''">id:{{ special2.item[6].goods_id }}</span>
              <span v-else>+ 选择商品</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 5 -->
    <div class="renovation_item">
      <el-checkbox class="item_title">长形轮播图</el-checkbox>
      <div class="item_bolck">
        <div class="item_inline">
          <div
            class="lunbo_item"
            v-for="(item, index) in long_lunbo"
            :key="index"
            style="width : 100%"
          >
            <cus-upload class="img" @change="add_tupian($event, index,5)" style="height: 250px" ></cus-upload>
            <div class="add_goods" @click="add_lunbo(index, 5 ,item.goods_id)">
                <span v-if="item.goods_id != ''">id:{{ item.goods_id }}</span>
                <span v-else>+ 选择商品</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      class="bba"
      center
      :show-close="false"
      custom-class="dialog"
      :visible.sync="add_goods_tan"
    >
      <div class="dia_search">
        <div class="sea_icon iconfont icon-chaxun"></div>
        <el-input
          class="search_inp"
          v-model="search_val"
          placeholder="请输入所要查询的商品关键字"
        ></el-input>
      </div>
      <div class="br_box"></div>
      <div class="dia_main">
        <div class="main_box" v-for="(item, index) in goods_list" :key="index">
          <el-radio-group v-model="radio" @change="change_radio(item.id)">
            <el-radio class="checkbox" :label="item.id">
              <img class="goods_tu" :src="item.img_src" />
              <div class="rt">
                <div class="rt_top">{{ item.title }}</div>
                <div class="rt_center">ID:{{ item.id }}</div>
              </div>
            </el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="footer_btn">
        <el-button class="bt_cancel" @click="cancel_btn">取消</el-button>
        <el-button
          class="bt_cancel"
          style="background-color: #409eff; color: #ffffff"
          @click="determine_btn"
          >确定</el-button
        >
      </div>
    </el-dialog>
    <div class="submit">
      <div class="btn" @click="submit">提交发布</div>
    </div>
  </div>
</template>

<script>
import CusUpload from "../from/cus-upload.vue";
import CusInput from "../from/cus-input.vue";
import axios from "../../common/axios";

export default {
  components: {
    CusUpload,
    CusInput,
  },
  data() {
    return {
      add_goods_tan: false,
      goods_list: [], //
      search_val: "", //搜索的文字
      radio: "", //选中的id
      lunbo_index: "", //轮播的index
      type: "",//点开的类型用来区别是拿个点开的弹窗
      //0
      head:'',
      // 1
      lunbo_list: [{ goods_id: "", img_src: "" }], //轮播
      // 2
      special1:{title:'',item:[{ goods_id: "", img_src: "" },{ goods_id: "", img_src: "" },{ goods_id: "", img_src: "" },{ goods_id: "", img_src: "" },{ goods_id: "", img_src: "" }]},
      // 4
      special2:{title:'',item:[{ goods_id: "", img_src: "" },{ goods_id: "", img_src: "" },{ goods_id: "", img_src: "" },{ goods_id: "", img_src: "" },{ goods_id: "", img_src: "" },{ goods_id: "", img_src: "" },{ goods_id: "", img_src: "" }]},
      // 5
      long_lunbo: [{ goods_id: "", img_src: "" }], //长形轮播
    };
  },
  mounted() {
    this.get_goods();
  },
  methods: {
    add_tupian(a, index ,type) {
      if (a.value == "") {
        if(type == 0){
          this.head = ''
          console.log(this.head)
        }
        //轮播图
        if (type == 1) {
          this.lunbo_list.splice(index, 1);
        }
        if (type == 2) {
          this.special1.item.forEach((atiem,aindex)=>{
              if(aindex == index){
                atiem.img_src = ''
                atiem.goods_id =''
              }
          })
        }
        if (type == 4) {
          this.special2.item.forEach((atiem,aindex)=>{
              if(aindex == index){
                atiem.img_src = ''
                atiem.goods_id =''
              }
          })
        }
        // 长形轮播图
        if (type == 5) {
          console.log(this.long_lunbo);
          this.long_lunbo.splice(index, 1);
        }
      }
      else {
        if(type == 0){
          this.head = a.value
          console.log(this.head);
        }
      //轮播图
        if (type == 1) {
          this.lunbo_list.forEach((item, aindex) => {
            if (index == aindex) {
              item.img_src = a.value;
            }
          });
          this.lunbo_list.push({
            img_src: "",
            goods_id: "",
          });
        }
        if(type == 2){
          this.special1.item.forEach((atiem,aindex)=>{
              if(aindex == index){
                atiem.img_src =  a.value
              }
          })
          console.log(this.special1);
        }
        if(type == 4){
          this.special2.item.forEach((atiem,aindex)=>{
              if(aindex == index){
                atiem.img_src =  a.value
              }
          })
          console.log(this.special2);
        }
        // 长形轮播图
        if (type == 5) {
          this.long_lunbo.forEach((item, lindex) => {
            if (index == lindex) {
              item.img_src = a.value;
            }
          });
          this.long_lunbo.push({
            goods_id: "",
            img_src: "",
          });
        }
      }
    },
    determine_btn() {
      this.add_goods_tan = false;
      console.log(this.type)
      //轮播图
      if (this.type == 1) {
        this.lunbo_list.forEach((item, index) => {
          if (index == this.lunbo_index) {
            item.goods_id = this.radio;
          }
        });
      }
      if (this.type == 2) {
        this.special1.item.forEach((item, index) => {
          if (index == this.lunbo_index) {
            item.goods_id = this.radio;
          }
        });
      }
      if (this.type == 4) {
        this.special2.item.forEach((item, index) => {
          if (index == this.lunbo_index) {
            item.goods_id = this.radio;
          }
        });
      }
        // 长形轮播图
      if (this.type == 5) {
        this.long_lunbo.forEach((item, index) => {
          if (index == this.lunbo_index) {
            item.goods_id = this.radio;
          }
        });
      }
    },
    change_input(e,type){
      if(type == 2){
        this.special1.title = e.value
      }
      if(type == 4){
        this.special2.title = e.value
      }
    },
    change_radio(id) {
      this.radio = id;
    },
    get_goods(id = 0) {
      let shop_list = [];
      let url = "/adminshop/coupons/get_goods.html";
      let data = { id, type: 0 };
      axios.post(url, data).then((res) => {
        let success = res.success;
        let data = res.data;
        if (success) {
          let list = data.list;
          if (list != undefined && list != null && list != "") {
            shop_list = list;
          }
        } else {
          console.log(res.error);
        }
        this.goods_list = shop_list;
      });
    },
    add_lunbo(index, a ,radio) {
      this.add_goods_tan = true;
      this.type = a;
      this.lunbo_index = index;
      this.radio = radio
    },
    submit(){
      console.log(this.long_lunbo,this.lunbo_list);
    },
    show_add_tan() {
      this.add_goods_tan = !this.add_goods_tan;
    },
    cancel_btn() {
      this.add_goods_tan = false;
    },
  },
};
</script>

<style scoped>
@import "./renovation.css";
.checkbox /deep/ .el-radio__label {
  display: flex !important;
}
</style>