<template>
	<div class="cus_upimg_box">
		<el-upload v-if="is_img" :action="websiteUrl + '/adminshop/uploads/uploadimg.html'" :limit="1"
			:on-preview="handlePictureCardPreview" :on-change="handleChange" :on-success="imgSuccess"
			:on-error="imgError" :accept="accept" class="cus_upimg" list-type="picture-card"
			:before-remove="beforeRemove" :class="{ disabled: uploadDisabled }" :file-list="fileList">
			<i class="el-icon-plus"></i>
		</el-upload>
		<el-dialog :modal-append-to-body="false" :visible.sync="imgVisible" width="30%" append-to-body>
			<img width="100%" :src="dialogImageUrl" alt="图片未找到" />
		</el-dialog>
	</div>
</template>

<script>
	import helper from "../../common/helper";

	export default {
		props: {
			imgname: {
				type: String,
				default: "",
			},
			src: {
				type: String,
				default: "",
			},
			accept: {
				type: String,
				default: "image/png,image/gif,image/jpg,image/jpeg",
			}
		},
		mounted() {
			this.get_img();
		},
		data() {
			return {
				//上传身份证正面相关参数
				limitCount: 1,
				uploadDisabled: false,
				// 是否显示预览图片
				imgVisible: false,
				// 预览图片url
				dialogImageUrl: "",
				fileList: [],
				websiteUrl: helper.websiteUrl,
				is_img: true,
			};
		},
		methods: {
			// onProgress(event, file, fileList) {
			//   console.log(event, file, fileList);
			//   this.uploadDisabled = false;
			// },
			// 图片上传成功
			get_img() {
				this.is_img = false
				this.$nextTick(() => {
					if (this.src) {
						let img_src = this.src;
						if (!(img_src.indexOf("http://") === 0 || img_src.indexOf("https://") === 0)) {
							img_src = this.websiteUrl + img_src;
						}
						this.dialogImageUrl = img_src
						this.fileList = [{
							name: this.imgname,
							url: img_src
						}];
					}
					this.is_img = true
				})
			},
			imgSuccess(response) {
				//   console.log(response);
				//   this.uploadDisabled = true;

				if (response.status == 1) {
					this.uploadDisabled = true;
					let img_src = response.img_src;
					let img_name = this.websiteUrl + img_src;
					// console.log(img_name);
					this.$nextTick(() => {
						this.fileList = [{
							name: response.name,
							url: img_name
						}];
						this.dialogImageUrl = img_name;
						this.chageimg(img_src);
						this.uploadDisabled = true;
						this.is_img = true;
					});
				} else {
					this.is_img = false;
					this.fileList = [];
					this.$nextTick(() => {
						this.fileList = [];
						this.dialogImageUrl = "";
						this.uploadDisabled = false;
						this.is_img = true;
					});
					this.chageimg();
					this.uploadDisabled = false;
					this.$message.error(response.msg);
				}
			},
			// 图片上传失败
			imgError(err, file, fileList) {
				console.log(err, file, fileList);
				this.uploadDisabled = true;
				this.chageimg();
			},
			// 预览图片
			handlePictureCardPreview() {
				this.imgVisible = true;
				this.uploadDisabled = true;
			},
			// 删除图片
			handleRemove() {
				//   console.log(file, fileList);

				this.uploadDisabled = false;
				this.chageimg();
				// this.hideUpload = fileList.length >= this.limitCount;
			},
			// 弹窗删除图片
			beforeRemove(file) {
				return this.$confirm(`确定移除 ${file.name}？`).then(() => {
					this.$message.success("已移除");
					this.uploadDisabled = false;
					this.chageimg();
				});
			},
			//图片更改
			handleChange(fileList) {
				this.uploadDisabled = fileList.length >= this.limitCount;
				this.uploadDisabled = true;
			},
			calculate_object_name(name) {
				return name;
			},
			chageimg(src = "") {
				this.$emit("change", {
					value: src
				});
			},
		},
	};
</script>

<style scoped>
	.disabled /deep/ .el-upload--picture-card {
		display: none;
	}

	/* .el-upload-list {
  display: none;
}
.disabled .el-upload-list {
  display: block;
} */
	.cus_upimg_box {
		position: relative;
		width: 100px;
		height: 100px;
	}

	.cus_upimg {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	.cus_upimg /deep/ .el-upload--picture-card {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
	}

	.cus_upimg /deep/ .el-upload-list--picture-card .el-upload-list__item {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 2;
	}

	.cus_upimg /deep/ .el-upload--picture-card .el-icon-plus {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	.cus_upimg /deep/ .el-upload-list__item .el-icon-close-tip {
		display: none !important;
	}
</style>
